import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";

import { Navbar, Nav, NavItem, Container } from "reactstrap";

import Logo from "../../../static/logo.png";

class Header extends Component {
  state = {
    navbar: "navbar",
    isOpen: false,
    navLinkColor: ""
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    if(this.props.location.pathname === '/en')
      this.setState({ navLinkColor: 'white' })
    else
      this.setState({ navLinkColor: 'black' })
  }

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState({ navbar: "navbar-scroll" });
      if(this.props.location.pathname === '/en')
        this.setState({ navLinkColor: 'black' })
      else
        this.setState({ navLinkColor: 'black' })
    } else {
      this.setState({ navbar: "navbar" });
      if(this.props.location.pathname === '/en')
        this.setState({ navLinkColor: 'white' })
      else
        this.setState({ navLinkColor: 'black' })
    }
  };

  render() {
    const { navbar, navLinkColor } = this.state;
    return (
      <Navbar fixed="top" expand="md" className={navbar}>
        <Container>
          <Link className="navbar-brand" to="/en">
            <img className="navbar-logo" src={Logo} alt="Site Logo" />
          </Link>
          <Nav className="d-none d-lg-flex ml-auto" navbar>
            <NavItem className="mr-4">
              <NavLink
                style={{ color: `${this.state.navLinkColor}` }}
                activeClassName="active_item"
                to="/en/about"
              >
                About Us
              </NavLink>
            </NavItem>
            <NavItem className="mr-4">
              <NavLink
                style={{ color: `${this.state.navLinkColor}` }}
                activeClassName="active_item"
                to="/en/values"
              >
                Our Values
              </NavLink>
            </NavItem>
            <NavItem className="mr-4">
              <NavLink
                style={{ color: `${this.state.navLinkColor}` }}
                activeClassName="active_item"
                to="/en/specialities"
              >
                Our Specialities
              </NavLink>
            </NavItem>
            <NavItem className="mr-4">
              <NavLink
                style={{ color: `${this.state.navLinkColor}` }}
                activeClassName="active_item"
                to="/en/pricing"
              >
                Our Fees
              </NavLink>
            </NavItem>
            <NavItem className="mr-4">
              <NavLink
                style={{ color: `${this.state.navLinkColor}` }}
                activeClassName="active_item"
                to="/en/contact"
              >
                Contact Us
              </NavLink>
            </NavItem>

              <NavItem className="mr-4">
                <NavLink
                  style={{ color: `${this.state.navLinkColor}` }}
                  activeClassName="active_item"
                  to="/en/testimonies"
                >
                  Testimonies
                </NavLink>
            </NavItem>

            <NavItem className="mr-1 navbar-link">
              <NavLink
                style={{ color: `${this.state.navLinkColor}` }}
                activeClassName="active_item"
                to="/en"
              >
                EN
              </NavLink>
            </NavItem>
            |
            <NavItem className="ml-1 mr-4 navbar-link">
              <NavLink
                style={{ color: `${this.state.navLinkColor}` }}
                activeClassName="active_item"
                to="/fr"
              >
                FR
              </NavLink>
            </NavItem>
          </Nav>
          <i
            className="fas fa-align-justify ml-auto d-block d-lg-none"
            style={{ cursor: "pointer" }}
            onClick={this.props.openMobileMenu}
          />
        </Container>
      </Navbar>
    );
  }
}

export default withRouter(Header);
