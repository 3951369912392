import React from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col } from 'reactstrap';

import BarnierImg from '../../../resources/images/about/Barnier.jpg';

const AboutBarnier = () => {
  return (
    <section>
      <Container>
        <Row className="align-items-center text-justify">
          <Col xs="12" md="6" className="text-center">
            <Fade left>
              <img src={BarnierImg} alt="Barnier" className="w-100" />
            </Fade>
            <p className="mt-5">
              <i>
                “Nothing is impossible! Only our own self-made limitations can refrain
                or stop us to reach the peak!”
              </i>
            </p>
          </Col>
          <Col xs="12" md="6">
            <Fade bottom cascade>
              <div>
                <p>
                  Luc M. de Barnier is a Psychotherapist and Life Coach, practicing for the last 30 years,
                  with many tools as Psychological approaches for one to one and couple sessions and with a
                  strong international business experience, specialised in the luxury field, as Individual and Corporate Certified Life Coach.
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutBarnier;
