import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';
import MobileMenu from './MobileMenu';

class index extends Component {
  state = {
    mobileMenuOpen: false
  };

  toggleMobileMenu = () => {
    this.setState(prevState => {
      return { mobileMenuOpen: !prevState.mobileMenuOpen };
    });
  };
  render() {
    return (
      <div>
        {/* {this.state.mobileMenuOpen && ( */}
        <MobileMenu
          closeMobileMenu={this.toggleMobileMenu}
          show={this.state.mobileMenuOpen}
        />
        {/* )} */}
        <Header openMobileMenu={this.toggleMobileMenu} />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}

export default index;
