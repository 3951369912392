import React from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col } from 'reactstrap';

import BarnierImg from '../../../resources/images/about/Barnier.jpg';

const AboutBarnier = () => {
  return (
    <section>
      <Container>
        <Row className="align-items-center text-justify">
          <Col xs="12" md="6" className="text-center">
            <Fade left>
              <img src={BarnierImg} alt="Barnier" className="w-100" />
            </Fade>
            <p className="mt-5">
              “Rien n’est impossible! Seules les limitations créées par nous-mêmes
              peuvent nous freiner ou nous empêcher d’atteindre le sommet!”
            </p>
          </Col>
          <Col xs="12" md="6">
            <Fade bottom cascade>
              <div>
                <p>
                   Luc M. de Barnier est Psychothérapeute et Accompagnant en Développement
                   Personnel depuis plus de 30 ans avec des nombreux outils en Psychothérapie
                   Individuelle et de Couple, et une expérience très riche dans le
                   domaine des Produits de Luxe à l’International, en tant que
                   Guide de Vie et Accompagnant Entrepreneurial Certifié.
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutBarnier;
