import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Table } from 'reactstrap';

import ValuesHeaderImage from '../../../resources/images/values/values-header.jpeg';

import Layout from '../layout';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: '60vh',
            background: `url(${ValuesHeaderImage}) no-repeat`,
            backgroundSize: 'cover'
          }}
        >
          <h1
            className="text-uppercase text-center  mt-5 header-text"
            style={{ fontSize: '3rem', fontWeight: 'bold' }}
          >
            Valeurs Du Cabinet
          </h1>
        </div>
        <Container className="mt-5">
          <Row>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <h2>
                  <strong>Vision</strong>
                </h2>
                <p style={{ fontSize: '1.3rem' }} className="mt-3">
                  Clarifier et comprendre le fonctionnement de nos mécanismes inconscients
                  sont les clefs permettant de modifier la répétition des schémas
                  familiaux et dépasser les limites de nos autoroutes neuronales.
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom delay={500}>
                <h2>
                  <strong>Mission</strong>
                </h2>
                <p style={{ fontSize: '1.2rem' }} className="mt-3">
                  Accueillir des êtres humains, seuls, en couple ou en famille dans un
                  environnement chaleureux et bienveillant afin de proposer des séances
                  thérapeutiques ou d’accompagnement dans un cadre aussi professionnel que
                  qualitatif en écoutant, guidant et conseillant hors de tout jugement,
                  basées seulement sur une totale empathie.
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom delay={1000}>
                <h2>
                  <strong>Valeurs</strong>
                </h2>
                <br />
                <Table
                  bordered
                  className="text-black"
                  style={{
                    // fontSize: '1.2rem',
                    width: '60%',
                    border: '2px solid',
                    margin: 'auto'
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Respect</td>
                      <td>Confidentialité</td>
                    </tr>
                    <tr>
                      <td colSpan="2">Ethique Professionnelle</td>
                    </tr>
                    <tr>
                      <td>Responsabilité</td>
                      <td>Confiance</td>
                    </tr>
                  </tbody>
                </Table>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }} className="mt-4">
                  “Il n’y a rien à craindre de la Vie, il y a tout à comprendre.”
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }} className="mt-4">
                  “En Empathie nous ne dirigeons rien, nous ne faisons que suivre. Il
                  suffit de ne rien faire, seulement d’être présent.”
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.171rem' }} className="mt-4">
                  “Vos croyances deviennent vos pensées, Vos pensées deviennent vos
                  paroles, Vos paroles deviennent vos actions, Vos actions deviennent vos
                  habitudes, Vos habitudes deviennent vos valeurs, Vos valeurs deviennent
                  votre destin. ”
                </p>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }}>
                  <strong>
                    <i>- Marie Curie</i>
                  </strong>
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }}>
                  <strong>
                    <i>- Marshall Rosenberg</i>
                  </strong>
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }}>
                  <strong>
                    <i>- Gandhi</i>
                  </strong>
                </p>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default index;
