import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody, CardText } from 'reactstrap';
import { NavLink, Link, withRouter } from "react-router-dom";


const Footer = () => {
  return (
    <div><hr/>
    <Row>
      <Col xs="12" md="6" lg={{ size: 'auto', offset: 1 }} align="center" style={{ height: '30px'}}>
        <Link to="/en/acceptable-use-policy" className="footer-link">
          Acceptable use policy
        </Link>
      </Col>
      <Col xs="12" md="6" lg={{ size: 'auto', offset: 1 }} align="center" style={{ height: '30px'}}>
        <Link to="/en/cookie-policy" className="footer-link">
          Cookie policy
        </Link>
      </Col>
      <Col xs="12" md="6" lg={{ size: 'auto', offset: 1 }} align="center" style={{ height: '30px'}}>
        <Link to="/en/disclaimer" className="footer-link">
          Disclaimer
        </Link>
      </Col>
      <Col xs="12" md="6" lg={{ size: 'auto', offset: 1 }} align="center" style={{ height: '30px'}}>
        <Link to="/en/privacy-policy" className="footer-link">
          Privacy policy
        </Link>
      </Col>
      <Col xs="12" md="6" lg={{ size: 'auto', offset: 1 }} align="center" style={{ height: '30px'}}>
        <Link to="/en/terms-and-condition" className="footer-link">
          Terms and condition
        </Link>
      </Col>
    </Row>
      <footer
        className="footer py-2 text-center text-black"
        style={{ backgroundColor: "white" }}
      >
        ©YEAH 2020
      </footer>
    </div>
  );
};

export default Footer;
