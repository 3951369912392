import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet";
import { Container, Row, Col, Card, CardHeader, CardBody, CardText, Spinner } from 'reactstrap';
import Layout from '../layout';

class index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      price: '',
      currency: '',
      isVerified: false,
      isChecked: false,
      isLoading: false
    }
  }

  componentDidMount() {
    document.getElementById('show-loader').style.display = "none";
    this.scrollTop()
  }

  scrollTop() {
    window.scroll(0, 0);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.isChecked !== this.state.isChecked && nextState.isVerified === true) {
      this.loadCheckoutSetting();
    }
  }

  loadCheckoutSetting() {

      this.getElement();

      setTimeout(() => {
        const source = document.createElement('script');
        source.type = 'text/javascript';
        source.async = true;
        //source.src = "https://cdn.checkout.com/sandbox/js/checkout.js";
        source.src = "https://cdn.checkout.com/js/checkout.js";

        this.instance.appendChild(source);
        document.getElementById('show-loader').style.display = "none";
        document.getElementById('script_sandbox').style.display = "block";
        this.setState({
          isLoading: true
        })
      }, 700);

  }

  getElement() {
    let element = document.getElementsByClassName('cko-EN-GB');
    if (element[element.length - 1] !== undefined) {
      element[element.length - 1].style.display = "none";
    }
  }

  validateEmail() {

    const email = document.getElementById('customEmail');
    const price = document.getElementById('customPrice');
    const currency = document.getElementById('customCurrency');

    if(!this.state.isLoading) {
      if (this.validateEmailField(email.value)) {
        this.setState({ email: email.value, price:this.validatePrice(price.value), currency:this.validateCurrency(currency.value), isVerified: true })
      }
    } else {
      this.clearPaymentForm();
    }
  }

 updateValues() {
    const email = document.getElementById('customEmail');
    const price = document.getElementById('customPrice');
    const currency = document.getElementById('customCurrency');

    this.setState({ email: email.value, price:this.validatePrice(price.value), currency:this.validateCurrency(currency.value), isVerified: true })
    //console.log("updateValues");
    //console.log(price.value);
  }

  validateCurrency(value) {
    var currencyValue = value.toUpperCase();
    if (currencyValue !== "EUR") {
      currencyValue = "AED";
    }
    return currencyValue;
  }

  clearCurrency() {
    document.getElementById('customCurrency').value = "";
  }

  validatePrice(value) {
    var currencyValue = value;
    if (currencyValue === "" || currencyValue === "e") {
      currencyValue = "700";
    }
    return currencyValue;
  }

  validatePaynow(check) {
    if (check) {
      const email = document.getElementById('customEmail');
      const price = document.getElementById('customPrice');
      const currency = document.getElementById('customCurrency');

      this.setState({ email: email.value, price:this.validatePrice(price.value), currency:this.validateCurrency(currency.value), isChecked: check })
      document.getElementById('script_sandbox').style.display = "none";
      document.getElementById('show-loader').style.display = "block";
      document.getElementById('dummy-button').style.display = "none";
    } else {
      this.clearPaymentForm();
    }
  }

  clearPaymentForm() {
    this.getElement();
    document.getElementById('show-loader').style.display = "block";
    document.getElementById('dummy-button').style.display = "block";
    this.setState({ email: '', price: '', currency: '', isVerified: false, isChecked: false, isLoading: false })
    document.getElementById('show-loader').style.display = "none";
  }

  validateEmailField(email) {
    const EmailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EmailRegexp.test(email);
  }

  showdialogPayment() {

    const { email, price, currency } = this.state;
    console.log("showdialogPayment");

    return (
      <>
        <Helmet>
          <script>
            {
              //pk_test_880e3df3-217b-46fd-8161-0cb8223a2fd4 pk_3bb1d22b-5ffa-48f1-94ce-96f1e64ac319
              `window.CKOConfig = {
                      publicKey: 'pk_3bb1d22b-5ffa-48f1-94ce-96f1e64ac319',
                      customerEmail: '${email}',
                      customerName: '${email}',
                      value: '${price * 100}',
                      currency: '${currency}',
                      cardFormMode: 'cardTokenisation',
                      paymentMode: 'cards',
                      renderMode: 1,
                      cardTokenised: function(event) {
                        console.log(event.data.cardToken);
                      }
                    };`
            }
          </script>``

        </Helmet>

        <div id="script_sandbox" ref={el => (this.instance = el)} />
      </>
    )
  }
  render() {

    const { email, price, currency, isChecked, isVerified } = this.state;

    return (
      <Layout>
        <div className="pricing-image" style={{ position: 'relative' }}>
        <Container className="pricing">
        <div className="fee">
            <h1
              className="text-uppercase"
              style={{ fontSize: '3rem', fontWeight: 'bold' }}
            >
              Our Fee
            </h1>
            <br />
            <Row>
              <Col md="4">
                <Fade top>
                  <Card>
                    <CardHeader>Psychology & Psycho-Therapy Individual Sessions</CardHeader>
                    <CardBody>
                      <CardText>AED 800/- Per Hour</CardText>
                      <CardText style={{ fontSize: '0.8rem', fontWeight: 'light'}} >To be paid at the end of each session.<br />Cancellation made less than 24h prior starting the session will be subject to paying full price.</CardText>
                    </CardBody>
                  </Card>
                </Fade>
              </Col>
              <Col md="4">
                <Fade top>
                  <Card>
                    <CardHeader>
                      Life Coach - Individual & Corporate Sessions
                    </CardHeader>
                    <CardBody>
                     <CardText>AED 800/- Per Hour</CardText>
                     <CardText style={{ fontSize: '0.8rem', fontWeight: 'light' }} >To be paid at the end of each session.<br />Cancellation made less than 24h prior starting the session will be subject to paying full price.</CardText>
                    </CardBody>
                  </Card>
                </Fade>
              </Col>
              <Col md="4">
                <Fade top>
                  <Card>
                    <CardHeader>Psychology & Psycho-Therapy Couple & Family Sessions</CardHeader>
                    <CardBody>
                      <CardText>AED 800/- Per Hour</CardText>
                      <CardText style={{ fontSize: '0.8rem', fontWeight: 'light'}} >To be paid at the end of each session.<br />Cancellation made less than 24h prior starting the session will be subject to paying full price.</CardText>
                    </CardBody>
                  </Card>
                </Fade>
              </Col>
            </Row>
          </div>
        </Container>

          <div className="payment-box" style={{visibility: 'hidden' }}>

            <form className="payment-form" method="POST" action="https://merchant.com/successUrl">
              <input
                id="customEmail"
                className="payment-input"
                type="email"
                name="customerEmail"
                onChange={() => this.validateEmail()}
                onKeyDown={() => this.clearPaymentForm()}
                placeholder="Enter your email"
              />
              <input
                id="customPrice"
                className="payment-input"
                type="number"
                name="customerPrice"
                onChange={() => this.updateValues()}
                onKeyDown={() => this.clearPaymentForm()}
                placeholder="700"
              />

              <input
                id="customCurrency"
                className="payment-input"
                type="text"
                list="currencies" autoComplete="new-password"
                name="customerCurrency"
                onFocus={() => this.clearCurrency()}
                placeholder="AED or EUR"
              />

              <datalist id="currencies">
                  <option value="AED"/>
                  <option value="EUR"/>
              </datalist>

              <label className="payment-label">
                <input type="checkbox"
                  checked={isChecked}
                  disabled={!isVerified}
                  onChange={() => this.validatePaynow(!isChecked)}
                />
                Verify data
              </label>
              <div id="show-loader"><Spinner animation="border" /></div>
              <button type="submit" id="dummy-button" className="payment-button" disabled>Pay now</button>
              {
                this.showdialogPayment()
              }
            </form>
          </div>
        </div>

      </Layout>
    );
  }
}

export default index;
