import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Table } from 'reactstrap';

import ValuesHeaderImage from '../../../resources/images/testimonies/testimonies-header.jpg';

import Layout from '../layout';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
        <div
          className="d-flex align-items-end justify-content-end"
          style={{
            height: '60vh',
            background: `url(${ValuesHeaderImage}) no-repeat`,
            backgroundSize: 'cover',
            padding: '40px'
          }}
        >
          <h1 className="text-right  mt-5 header-text italic-quote">
          "Your tokens of sympathy, your sincere words will be entitled to brighten our website like the sunrise on the sand of a beach...
          <br/>
          Thanks so much in advance !"
          </h1>
        </div>
        <Container className="mt-5">
          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}>
              <Fade bottom>
                <p style={{ fontSize: '1.1rem' }} className="mt-3">
                Comfort-comfort- - -<br/>
                Towards a discernment of the rays of light - - -

                </p>
                <p style={{ fontSize: '1.1rem' }}>
                  <strong>
                    <i>- YB</i>
                  </strong>
                </p>

              </Fade>
            </Col>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom delay={500}>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              Appeasement and well-being in the midst of this release of negative energy on all media and social networks. Messages that guide us and that resonate within us. Thank you
              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- MW</i>
                </strong>
              </p>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              There are encounters in the course of a life that do not leave you indifferent! Luc and Sylvie are now part of it. They were complementary and benevolent in this work on me, they accompanied and guided me when I needed them most. Now I can move on and heal ... thank you.

              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- Laurence</i>
                </strong>
              </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              Thanks to sylvie and luc for their kindness, thoughfullness and presence which allowed me to open a part of myself that i didnt have access to.

              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- YB</i>
                </strong>
              </p>
              </Fade>
            </Col>
          </Row>



          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
 With an intense and outstanding outcome, Sylvie and Luc provided me with all what is needed to thrive and overcome any personal and professional challenges. Many thanks!

              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- GGK</i>
                </strong>
              </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              Thanks Luc and Sylvie for your presence and your support during this chaotic time. You open my eyes and above all you did give me the power to face my dark sides keeping in mind there is no risk and only better will come, which is the case. Indeed, thanks to both of You.
              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                <i>- A-V</i>
                </strong>
              </p>
              </Fade>
            </Col>
          </Row>

          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              After twenty years of living together, our couple needed a new start.
              We started our couples therapy with Sylvie and Luc.
              Following the first session, we each continued with individual therapy. We both not only saved our couple, but we found the keys to personal development.
              The next step was to include our children in this regimen.
              In short, I can only advise Sylvie and Luc.
              Both are filled with love to share, all in a professional approach.
              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- BD</i>
                </strong>
              </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
There are explanations of how we have built ourselves - which appear more or less crystal clear to us. Luc brings you the necessary light on certain dark episodes of our past, but not only! it brings you a logic, which helps to put certain points on the "i". I didn't feel fragile before the sessions and didn't feel any particular need - but her help made me feel more relaxed about the existential question of who you really are. Time flies in his company              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- TG</i>
                </strong>
              </p>
              </Fade>
            </Col>
          </Row>

          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              A big thank you to Sylvie and Luc for their precious advice and explanations provided in each of the videos offered.
              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- RM</i>
                </strong>
              </p>
              </Fade>
            </Col>

          </Row>

        </Container>
      </Layout>
    );
  }
}

export default index;
