import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Table } from 'reactstrap';


import Layout from '../../layout';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
        <div style={{ marginTop: '20rem' }}>

        <Container className="text-left">

        <p>
        <h2><strong>Acceptable Use Policy</strong></h2>
</p>
<p>
    <strong></strong>
    <br/>
</p>
<p>
    These acceptable use policy ("Acceptable Use Policy", "AUP", "Policy") is
    an agreement between JOY &amp; GOLDEN LINK FZ-LLC ("JOY &amp; GOLDEN LINK
    FZ-LLC", "us", "we" or "our") and you ("User", "you" or "your"). This
    Policy sets forth the general guidelines and acceptable and prohibited uses
    of the www.yeahpsychologydubai.life website and any of its products or
    services (collectively, "Website" or "Services").
</p>
<p>
    <strong>Prohibited activities and uses</strong>
</p>
<p>
    You may not use the Services to engage in activity that is illegal under
    applicable law, that is harmful to others, or that would subject us to
    liability, including, without limitation, in connection with any of the
    following, each of which is prohibited under this Policy:
</p>
<ul>
    <li>
        Disclosing sensitive personal information about others.
    </li>
    <li>
        Collecting, or attempting to collect, personal information about third
        parties without their knowledge or consent.
    </li>
    <li>
        Threatening harm to persons or property or otherwise harassing
        behavior.
    </li>
    <li>
        Purchasing any of the offered Services on someone else’s behalf.
    </li>
    <li>
        Misrepresenting or fraudulently representing products or services.
    </li>
    <li>
        Infringing the intellectual property or other proprietary rights of
        others.
    </li>
    <li>
        Facilitating, aiding, or encouraging any of the above activities
        through our Services.
    </li>
</ul>
<p>
    <strong>System abuse</strong>
</p>
<p>
    Any User in violation of our Services security is subject to criminal and
    civil liability, as well as immediate account termination. Examples
    include, but are not limited to the following:
</p>
<ul>
    <li>
        Use or distribution of tools designed for compromising security of the
        Services.
    </li>
    <li>
        Intentionally or negligently transmitting files containing a computer
        virus or corrupted data.
    </li>
    <li>
        Accessing another network without permission, including to probe or
        scan for vulnerabilities or breach security or authentication measures.
    </li>
    <li>
        Unauthorized scanning or monitoring of data on any network or system
        without proper authorization of the owner of the system or network.
    </li>
</ul>
<p>
    <strong>Service resources</strong>
</p>
<p>
    You may not consume excessive amounts of the Services or use the Services
    in any way which results in performance issues or which interrupts the
    services for other Users. Prohibited activities that contribute to
    excessive use, include without limitation:
</p>
<ul>
    <li>
        Deliberate attempts to overload the Services and broadcast attacks
        (i.e. denial of service attacks).
    </li>
    <li>
        Engaging in any other activities that degrade the usability and
        performance of our Services.
    </li>
</ul>
<p>
    <strong>Security</strong>
</p>
<p>
    You take full responsibility for maintaining reasonable security
    precautions for your account. You are responsible for protecting and
    updating any login account provided to you for our Services. You must
    protect the confidentiality of your login details, and you should change
    your password periodically.
</p>
<p>
    <strong>Enforcement</strong>
</p>
<p>
    We reserve our right to be the sole arbiter in determining the seriousness
    of each infringement and to immediately take corrective actions, including
    but not limited to:
</p>
<ul>
    <li>
        Suspending or terminating your Service with or without notice upon any
        violation of this Policy. Any violations may also result in the
        immediate suspension or termination of your account.
    </li>
    <li>
        Disabling or removing any content which is prohibited by this Policy,
        including to prevent harm to others or to us or our Services, as
        determined by us in our sole discretion.
    </li>
    <li>
        Reporting violations to law enforcement as determined by us in our sole
        discretion.
    </li>
    <li>
        A failure to respond to an email from our abuse team within 2 days, or
        as otherwise specified in the communication to you, may result in the
        suspension or termination of your Services.
    </li>
</ul>
<p>
    Suspended and terminated User accounts due to violations will not be
    re-activated.
</p>
<p>
    Nothing contained in this Policy shall be construed to limit our actions or
    remedies in any way with respect to any of the prohibited activities. We
    reserve the right to take any and all additional actions we may deem
    appropriate with respect to such activities, including without limitation
    taking action to recover the costs and expenses of identifying offenders
    and removing them from our Services, and levying cancellation charges to
    cover our costs. In addition, we reserve at all times all rights and
    remedies available to us with respect to such activities at law or in
    equity.
</p>
<p>
    <strong>Reporting violations</strong>
</p>
<p>
    If you have discovered and would like to report a violation of this Policy,
    please contact us immediately. We will investigate the situation and
    provide you with full assistance.
</p>
<p>
    <strong>Changes and amendments</strong>
</p>
<p>
    We reserve the right to modify this Policy or its terms relating to the
    Website or Services at any time, effective upon posting of an updated
    version of this Policy on the Website. When we do, we will revise the
    updated date at the bottom of this page. Continued use of the Website after
    any such changes shall constitute your consent to such changes.
</p>
<p>
    <strong>Acceptance of this policy</strong>
</p>
<p>
    You acknowledge that you have read this Policy and agree to all its terms
    and conditions. By using the Website or its Services you agree to be bound
    by this Policy. If you do not agree to abide by the terms of this Policy,
    you are not authorized to use or access the Website and its Services.
</p>
<p>
    <strong>Contacting us</strong>
</p>
<p>
    If you would like to contact us to understand more about this Agreement or
    wish to contact us concerning any matter relating to it, you may write a
    letter to JOY &amp; GOLDEN LINK FZ-LLC, PO Box 476306, Dubai, United Arab
    Emirates.
</p>
        </Container>

        </div>

      </Layout>
    );
  }
}

export default index;
