import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Button } from 'reactstrap';

import aboutImage from "../../../resources/images/about/aboutimage.jpg";
import aboutImageColor from "../../../resources/images/about/aboutimagecolor.jpg";

class GeneralSection extends Component {
  state={ AboutImage: aboutImage }

  handleMouseOver = () => {
    this.setState({ AboutImage: aboutImageColor })
  }

  handleMouseOut = () => {
    this.setState({ AboutImage: aboutImage })
  }

  render() {  
    return (
      <section>
        <Container className="text-center">
          <Zoom>
            <img 
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
              src={this.state.AboutImage} 
              alt="about author" 
              className="rounded img-couple" />
          </Zoom>
          <Row className="mt-4">
            <Col xs="12" md="12" className="text-center">
              <Fade bottom cascade>
                <div>
                  <p>
                    <strong>« Notre plus belle histoire d’Amour… c’est Vous ! »</strong>
                  </p>
                  <p>
                    Nous avons décidé de consacrer notre Vie à l’exploration de l’Être
                    humain. Nous étions deux ados lorsque notre professeur de philosophie
                    nous a lu un texte de Sigmund Freud qui disait que le "MOI" n’était pas
                    maître chez lui et que plusieurs aspects de nous étaient inconscients et
                    pouvaient, de ce fait, agir sur nous et nous enlever notre "liberté" à
                    vivre notre vie.
                  </p>
                  <p>
                    Nous étions si jeunes et tellement en demande de liberté et de
                    compréhension du Sens de notre Vie. Notre question essentielle était:
                  </p>
                  <p>
                    <strong>« Que sommes nous venus faire sur cette terre ? »</strong>
                  </p>
                  <p>
                    Nous avons choisi des études de Psychologie qui nous ont données
                    beaucoup de connaissances théoriques notamment dans le développement de
                    l'enfant. Nous avons réalisé l’immense fragilité de l’enfant et son
                    besoin vital d’être Accueilli par l’extérieur, les parents bien sûr mais
                    aussi la famille, l’école, les institutions, et nous avons pris
                    conscience de tous nos Conditionnements qui deviennent nos Croyances
                    Existentielles!
                  </p>
                  <p>
                    Cela a fait grandir en nous la notion de Responsabilité Personnelle et
                    Universelle, et nous avons intégré la phrase de Ghandi:
                  </p>
                  <p>
                    <strong>“Sois le changement que tu veux voir dans le Monde. ”</strong>
                  </p>
                </div>
              </Fade>
              <br />
            </Col>
          </Row>
        </Container>
        <div
          className="about-image py-5 text-white text-center font-weight-bold"
          style={{ position: 'relative' }}
        >
          <div className="layer" />
          <Container style={{ position: 'relative' }}>
            <Fade bottom cascade>
              <div>
                <p>
                  Nous sommes tous deux diplômés de l’Université des Sciences Humaines de
                  Nice Sophia Antipolis (FRANCE) de Psychologie Sociale et Clinique.
                </p>
                <p>
                  Après avoir étudié les plus importantes approches psychologiques et
                  thérapeutiques, après avoir suivi nous-mêmes un profond travail intérieur
                  avec plusieurs thérapeutes et en groupe de thérapie, et grâce à nos chers
                  patients qui nous ont fait confiance, nous avons créé notre propre
                  approche thérapeutique basée sur « Nos 7 Identités » ainsi qu’une approche
                  unique de thérapie de couple et familiale basée sur l’interaction
                  complémentaire de deux psychothérapeutes dans la même séance
                  thérapeutique.
                </p>
                <p>
                  Nous vous invitons à consulter le lien suivant sur:
                  <span className="pull-right">
                    <Button color="primary" className="ml-3">
                      <a
                        href="https://www.youtube.com/watch?v=o4t76g0I7zw"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white"
                      >
                        Nos 7 Alliés de Vie (Versions Française avec sous-titrages Anglais)
                      </a>
                    </Button>
                  </span>
                </p>
                <p>
                  Dans l’accueil des couples ou des familles, nous proposons d’être
                  ensemble: cela offre une vraie compétence avec deux psychothérapeutes
                  expérimentés et un symbole de cet équilibre “féminin-masculin” ou
                  “yin-yang” ou “l’être et le faire”, permet de faciliter les échanges et,
                  notamment pour les hommes quelquefois plus intimidés, la mise en lumière
                  de certains blocages.
                </p>
              </div>
            </Fade>
          </Container>
        </div>
      </section>
    );
  }
};

export default GeneralSection;
