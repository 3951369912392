import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import SylvieImg from '../../../resources/images/about/sylvie.jpg';
import SylvieBook1 from '../../../resources/images/about/sylviebook1.png';
import SylvieBook2 from '../../../resources/images/about/sylviebook2.png';

class AboutSylvie extends Component {
  state = { modal: false };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal
    }));
    return false;
  };

  render() {
    return (
      <section>
        <Container>
          <Row className="text-justify">
            <Col xs="12" md="6">
              <Fade bottom cascade>
                <div>
                  <p>
                    Sylvie de Barnier est Psychologue-Psychotherapeute depuis plus de 35
                    ans, en France puis à Dubai depuis 2002. En France, elle a débuté en
                    tant que Psychologue au Conseil Général des Alpes-Maritimes (Services
                    Adoption, Gérontologie puis Éducation Nationale). Elle ouvre son
                    Cabinet de Psychothérapie en 1993. Sa passion des adolescents se
                    poursuit à Dubai où elle exerce depuis 2003 en tant que Psychologue
                    Scolaire au sein du{' '}
                    <a href="javascript:void(0)" onClick={this.toggle}>
                      Lycée Français International Georges Pompidou à Dubai (lien)
                    </a>
                    .
                  </p>
                  <p>
                    Elle a publié deux Carnets de Vie "à lire et à écrire" préfacés par{' '}
                    <a
                      href="http://www.j-salome.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Jacques SALOMÉ
                    </a>
                    : <i>"À toi, ma mère" et "À toi, mon enfant"</i> – Pygmalion Collection / Flammarion
                  </p>
                </div>
              </Fade>
              <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
                <ModalHeader toggle={this.toggle}>
                  Lycée Français International Georges Pompidou, Dubai
                </ModalHeader>
                <ModalBody>
                  <p>
                    En 2001, à notre arrivée aux UAE, j'inscrivais mon fils Aurelien au
                    Lycee Francais Georges POMPIDOU de Sharjah.
                  </p>
                  <p>
                    Ce lycée abritait quelques centaines d'élèves de la maternelle à
                    la terminale, dans une ambiance assez familiale.
                  </p>
                  <p>
                    Travaillant avec le Professeur Marcel Rufo de Marseille, j'avais eu
                    comme mission au sein du Conseil Général des Alpes Maritimes,
                    d'organiser des espaces de parole pour les adolescent(e)s en échec
                    scolaire et cela m'avait tellement appris sur l'importance de cette
                    période de grande fragilité de notre jeunesse.
                  </p>
                  <p>
                    J’avais eu l'opportunité de rencontrer des éducateurs spécialisés, des
                    art-thérapeutes, des sophrologues (très novateur à l'époque !),des
                    spécialistes des addictions, des troubles alimentaires partageant tous
                    et toutes cette Envie de comprendre et d'accompagner ces jeunes
                    patients dans la Construction de leur Confiance et l'Amour d'eux
                    -mêmes.
                  </p>
                  <p>
                    Dans ce Lycee, loin de la France, j'eu le Bonheur d'un coup de foudre
                    amical en la personne de Nicole Farouk, responsable du service
                    Infirmerie. Nicole vivait des moments de profonde interrogation face à
                    certains adolescents un peu perdus, loin de leur famille élargie, avec
                    beaucoup de changements géographiques (des parents qui sont mutés
                    chaque 2 ou 3 ans dans de nouveaux pays), des ruptures amicales, une
                    sécurité affective plus fragile etc.
                  </p>
                  <p>
                    Nous partagions la même approche basée sur une profonde Ecoute et
                    Présence, et nous avons mis en place la “Cellule d'Ecoute” au niveau
                    du Collège et du Lycée qui est toujours active aujourd’hui au sein de
                    ce (beau) Lycée Georges POMPIDOU qui accueille aujourd'hui plus de
                    2000 élèves, et qui a déménagé, depuis 2006, à Dubai.
                  </p>
                  <p>
                    Chaque année, je me réjouis de pouvoir travailler en équipe avec des
                    Proviseurs, des Professeurs, du Personnel éducatif de grande qualité,
                    très conscients de l'importance de nos Paroles, de nos attitudes et
                    conseils auprès de ces adolescents, symbole de notre Avenir.
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
              <Container>
                <Row>
                  <Col xs="12" md="6">
                    <Fade up>
                      <img src={SylvieBook1} alt="SylvieBook" className="w-100 rounded about-image-table"/>
                    </Fade>
                  </Col>
                  <Col xs="12" md="6">
                    <Fade up delay={500}>
                      <img src={SylvieBook2} alt="SylvieBook" className="w-100 rounded about-image-table" />
                    </Fade>
                  </Col>
                  <br/>
                </Row>
              </Container>
            </Col>
            <Col xs="12" md="6" className="text-center">
              <Fade right>
                <img src={SylvieImg} alt="Barnier" className="w-100 rounded" />
              </Fade>
              <br />
              <br />
              <p>
                “Si tu n’aimes pas ce que tu récoltes, alors change ce que tu sèmes!”
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default AboutSylvie;
