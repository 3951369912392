import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';

import { Container, Row, Col } from 'reactstrap';

import Layout from '../layout';

import SpecialitiesHeaderImage from '../../../resources/images/specialities/specialities-header.jpg';
import image1 from '../../../resources/images/specialities/img1.jpeg';
import image2 from '../../../resources/images/specialities/img2.jpg';
import image3 from '../../../resources/images/specialities/img3.jpg';
import image4 from '../../../resources/images/specialities/img4.jpg';
import image5 from '../../../resources/images/specialities/img5.jpg';

class index extends Component {
  render() {
    return (
      <Layout>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: '60vh',
            background: `url(${SpecialitiesHeaderImage}) no-repeat`,
            backgroundSize: 'cover'
          }}
        >
          <h1
            className="text-uppercase text-center  mt-5 header-text"
            style={{ fontSize: '3rem', fontWeight: 'bold' }}
          >
            Spécialités
          </h1>
        </div>
        <section className="mt-5">
          <Container>
            <Row>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img
                    src={image1}
                    alt="Enfants, Adoloescents, Famille"
                    style={{ width: '90%' }}
                  />
                </Zoom>
              </Col>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed right>
                  <h3>Enfants, Adoloescents, Famille</h3>
                  <ul className="mt-3">
                    <li>Anxiété</li>
                    <li>Phobie</li>
                    <li>Dépression</li>
                    <li>Anorexie, Boulimie</li>
                    <li>Harcèlement</li>
                  </ul>
                </LightSpeed>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed left>
                  <h3>Psychothérapie</h3>
                  <ul className="ml-3">
                    <li>Individuelle</li>
                    <li>De Couple</li>
                    <li>Familiale</li>
                    <li>De Groupe</li>
                  </ul>
                </LightSpeed>
              </Col>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img src={image2} alt="Psychothérapie" style={{ width: '90%' }} />
                </Zoom>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img
                    src={image3}
                    alt="Syndrome Post-Traumatique"
                    style={{ width: '90%' }}
                  />
                </Zoom>
              </Col>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed right>
                  <h3>Syndrome Post-Traumatique</h3>
                  <p className="mt-3">
                    Méthodes spécifiques de résilience visant à dépasser le traumatisme
                  </p>
                  <p>(Hypnose, PNL, etc.)</p>
                </LightSpeed>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed left>
                  <h3>Tests Psychologiques</h3>

                  <p className="mt-3">
                    Évaluations Cognitives et Émotionnelles (QI etc.)
                  </p>
                </LightSpeed>
              </Col>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img src={image4} alt="Tests Psychologiques" style={{ width: '90%' }} />
                </Zoom>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img
                    src={image5}
                    alt="Guide de Vie & Consultant Professionnel"
                    style={{ width: '90%' }}
                  />
                </Zoom>
              </Col>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed right>
                  <h3>Guide de Vie & Consultant Professionnel</h3>
                  <p className="mt-3">
                    Accompagnement Personnalisé selon la personnalité du demandeur et son
                    objectif afin de résoudre les difficulteés en entreprise, au sein d’un
                    groupe ou d’une équipe.
                  </p>
                </LightSpeed>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    );
  }
}

export default index;
