import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class ChangePath extends Component {
  render() {
    return <Redirect to="/en" />;
  }
}

export default ChangePath;
