import React, { Component } from 'react';

import Layout from '../layout';
import ReachUs from './ReachUs';
// import ContactForm from "./ContactForm";

import ContactHeaderImage from '../../../resources/images/contact/contact-header-image-crop.jpeg';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: '60vh',
            background: `url(${ContactHeaderImage}) no-repeat`,
            backgroundSize: 'cover'
          }}
        >
          <h1
            className="text-uppercase mt-5"
            style={{ fontSize: '3rem', fontWeight: 'bold' }}
          >
            Contact Us
          </h1>
        </div>
        <ReachUs />
        {/* <ContactForm /> */}
        <iframe
          title="Location Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.7336635438423!2d55.249922415006445!3d25.04311108396805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjXCsDAyJzM1LjIiTiA1NcKwMTUnMDcuNiJF!5e0!3m2!1sen!2sin!4v1562615378105!5m2!1sen!2sin"
          style={{
            width: '100%',
            height: '450px',
            border: '0'
          }}
          allowFullScreen
        />
      </Layout>
    );
  }
}

export default index;
