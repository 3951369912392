import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Button } from 'reactstrap';

import aboutImage from "../../../resources/images/about/aboutimage.jpg";
import aboutImageColor from "../../../resources/images/about/aboutimagecolor.jpg";

class GeneralSection extends Component {
  state={ AboutImage: aboutImage }

  handleMouseOver = () => {
    this.setState({ AboutImage: aboutImageColor })
  }

  handleMouseOut = () => {
    this.setState({ AboutImage: aboutImage })
  }

  render() {  
    return (
      <section>
        <Container className="text-center">
          <Zoom>
            <img 
              onMouseOver={this.handleMouseOver}
              onMouseOut={this.handleMouseOut}
              src={this.state.AboutImage} 
              alt="about author" 
              className="rounded img-couple" />
          </Zoom>
          <Row className="mt-4">
            <Col xs="12" md="12" className="text-center">
              <Fade bottom cascade>
                <div>
                  <p>
                    <strong>“You Are Our Most Amazing Love Story!”</strong>
                  </p>
                  <p>
                    We decided to devote our life to the exploration of what it means to be
                    human. We were teenagers when our philosophy teacher read us a text from
                    Sigmund Freud, which stated that the ‘SELF’ was not the master at home
                    and that several aspects of us were unconscious – therefore, could act
                    on us and take away our ‘freedom’ to live our life.
                  </p>
                  <p>
                    Since we were teenagers with desires to be free and to understand the
                    meaning of our lives, our key question we wanted an answer to was:
                  </p>
                  <p>
                    <strong>“What is the purpose of our coming on this earth?”</strong>
                  </p>
                  <p>
                    So, we chose to study Psychology, which led to gaining a wealth of
                    knowledge, especially in the development of the child.
                  </p>
                  <p>
                    We realized the immense fragility of children, their vital need to be
                    welcomed and secured by the surrounding, the parents of course, but also
                    the family, school and institutions. We became aware of all our
                    conditioning patterns, which become our existential beliefs!
                  </p>
                  <p>
                    With this, we grew ourselves in the notion of Personal and Universal
                    Responsibility and we now follow a belief of Gandhi’s:
                  </p>
                  <p>
                    <strong>“Be the change you want to see in the world.”</strong>
                  </p>
                </div>
              </Fade>
              <br />
            </Col>
          </Row>
        </Container>
        <div
          className="about-image py-5 text-white text-center font-weight-bold"
          style={{ position: 'relative' }}
        >
          <div className="layer" />
          <Container style={{ position: 'relative' }}>
            <Fade bottom cascade>
              <div>
                <p>
                  We are both Clinical and Social Psychology graduates of Université Nice
                  Sophia Antipolis, Nice, France.
                </p>
                <p>
                  After studying and integrating (by pursuing deep inner psychological and
                  group therapy follow-ups) all major psychotherapies and psychoanalysis
                  theories – and thanks to the trust of our patients, we have created our
                  own, self made therapeutic tools, based on ‘The 7 Identities’, as well as
                  a unique couple and family therapeutic approach, involving two therapists
                  in the same session – unique to this region.
                </p>
                <p>
                  Please refer to:
                  <span className="pull-right">
                    <Button color="primary" className="ml-3">
                      <a
                        href="https://www.youtube.com/watch?v=QimMFy4rTPc"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white"
                      >
                        The 7 Identities (French & English)
                      </a>
                    </Button>
                  </span>
                </p>
                <p>
                  For couple or family sessions, we suggest to be together: This offers a
                  valuable approach of two experienced therapists, as well as the symbol of
                  harmony between Feminine and Masculine sides – like the Ying and Yang or
                  ‘The Be’ and ‘The Do’, which invites sharing… especially, from men, who
                  are sometimes, more sensitive to highlight their limitations.
                </p>
              </div>
            </Fade>
          </Container>
        </div>
      </section>
    );
  }
};

export default GeneralSection;
