import React from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col } from 'reactstrap';

const InformationText = () => {
  return (
    <section>
      <Container>
        <Row className="homeNfo text-center">
          <Col>
            <Fade bottom cascade>
              <div>
                <p>
                  Following our passion for understanding ‘Human Beings’ led us on a
                  mission to access our limitless resources – which were silent since
                  childhood – in to a secure, warm and cozy environment, where we shall be
                  extremely happy to welcome you to.
                </p>
                <p>
                  Listening to your story, understanding your concerns, highlighting your
                  inner fears, as well as your huge potential within the respect of your
                  rhythm – is the key of our mission for you.
                </p>
                <p>
                  Suggesting a way to reconnect with yourself in order to transform – to
                  pure awareness – your wounds, fragilities, limitations in wonderful
                  inner strengths, discovering the meaning of your life are parts of this
                  journey as well.
                </p>
                <p>
                  So, when you feel lost or not aligned anymore with what your vision of
                  life was supposed to be, know this, that it’s so precious to meet
                  genuinely dedicated and caring persons, who in full empathic capacity,
                  will listen intently to every word you say or even don’t, without any
                  judgement.
                </p>
                <p>
                  Your first step is the most difficult one, as it is linked with the
                  value you consider you are worth. Therefore are you ready to spread your
                  wings to access the adventure of discovering yourself?!
                </p>
                <p>
                  Offering mindfulness, your happiness to the most important people
                  surrounding your life, paying tribute to your own vital energy – all of
                  these could be your targets!
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InformationText;
