import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Table } from 'reactstrap';


import Layout from '../../layout';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
      <div style={{ marginTop: '20rem' }}>

      <Container className="text-left">
      <p>
          <h2><strong>Disclaimer</strong></h2>
      </p>
      <p>
          <strong></strong>
          <br/>
      </p>
      <p>
          This disclaimer ("Disclaimer", "Agreement") is an agreement between JOY
          &amp; GOLDEN LINK FZ-LLC ("JOY &amp; GOLDEN LINK FZ-LLC", "us", "we" or
          "our") and you ("User", "you" or "your"). This Disclaimer sets forth the
          general guidelines, terms and conditions of your use of the
          www.yeahpsychologydubai.life website and any of its products or services
          (collectively, "Website" or "Services").
      </p>
      <p>
          <strong>Representation</strong>
      </p>
      <p>
          Any views or opinions represented on the Website respect the views and
          opinions of JOY &amp; GOLDEN LINK FZ-LLC, its affiliates, Content creators
          or employees. Any views or opinions are not intended to malign any
          religion, ethnic group, club, organization, company, or individual.
      </p>
      <p>
          <strong>Content and postings</strong>
      </p>
      <p>
          You may not modify, print or copy any part of the Website. Inclusion of any
          part of this Website in another work, whether in printed or electronic or
          another form or inclusion of any part of the Website in another website by
          embedding, framing or otherwise without the express permission of JOY &amp;
          GOLDEN LINK FZ-LLC is prohibited.
      </p>
      <p>
          <strong>Medical disclaimer</strong>
      </p>
      <p>
          The information available on the Website is for general health information
          only and is not intended to be a substitute for professional medical
          advice, diagnosis or treatment. You should not rely exclusively on
          information provided on the Website for your health needs. All specific
          medical questions should be presented to your own health care provider and
          you should seek medical advice regarding and before making any changes
          related to your health.
      </p>
      <p>
          If you choose to use the information available on the Website without prior
          consultation with and consent of your physician, you are agreeing to accept
          full responsibility for your decisions and agreeing to hold harmless JOY
          &amp; GOLDEN LINK FZ-LLC, its agents, employees, contractors, and any
          affiliated companies from any liability with respect to injury or illness
          to you or your property arising out of or connected with your use of this
          information.
      </p>
      <p>
          <strong>Reviews and testimonials</strong>
      </p>
      <p>
          Testimonials are received in various forms through a variety of submission
          methods. They are individual experiences, respecting experiences of those
          who have used products or services on the Website in some way or another.
          However, they are individual results and results do vary. We do not claim
          that they are typical results that consumers will generally achieve. The
          testimonials are not necessarily representative of all of those who will
          use our products or services. JOY &amp; GOLDEN LINK FZ-LLC is not
          responsible for the opinions or comments posted on the Website, and does
          not necessarily share them. All opinions expressed are strictly the views
          of the poster or reviewer.
      </p>
      <p>
          The testimonials displayed are given verbatim except for grammatical or
          typing error corrections. Some testimonials may have been edited for
          clarity, or shortened in cases where the original testimonial included
          extraneous information of no relevance to the general public. Testimonials
          may be reviewed for authenticity before they are posted for public viewing.
      </p>
      <p>
          <strong>Indemnification and warranties</strong>
      </p>
      <p>
          While we have made every attempt to ensure that the information contained
          on the Website is correct. JOY &amp; GOLDEN LINK FZ-LLC is not responsible
          for any errors or omissions, or for the results obtained from the use of
          this information. All information and Services on the Website are provided
          "as is", with no guarantee of completeness, accuracy, timeliness or of the
          results obtained from the use of this information or Services, and without
          warranty of any kind, express or implied. In no event will JOY &amp; GOLDEN
          LINK FZ-LLC, or its partners, employees or agents, be liable to you or
          anyone else for any decision made or action taken in reliance on the
          information on the Website or Services, or for any consequential, special
          or similar damages, even if advised of the possibility of such damages.
          Furthermore, information contained on the Website and any pages linked to
          and from it are subject to change at any time and without warning.
      </p>
      <p>
          We reserve the right to modify this Disclaimer relating to the Website,
          products or services at any time, effective upon posting of an updated
          version of this Disclaimer on the Website. When we do we will revise the
          updated date at the bottom of this page. Continued use of the Website after
          any such changes shall constitute your consent to such changes.
      </p>
      <p>
          <strong>Acceptance of this disclaimer</strong>
      </p>
      <p>
          You acknowledge that you have read this Disclaimer and agree to all its
          terms and conditions. By accessing the Website you agree to be bound by
          this Disclaimer. If you do not agree to abide by the terms of this
          Disclaimer, you are not authorized to use or access the Website.
      </p>
      <p>
          <strong></strong>
          <br/>
      </p>
      <p>
          <strong>Contacting us</strong>
      </p>
      <p>
          If you would like to contact us to understand more about this Agreement or
          wish to contact us concerning any matter relating to it, you may write a
          letter to JOY &amp; GOLDEN LINK FZ-LLC, PO Box 476306, Dubai, United Arab
          Emirates.
      </p>
      </Container>

      </div>
      </Layout>
    );
  }
}

export default index;
