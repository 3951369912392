import React, { Component } from "react";

import Layout from "../layout";
import GeneralSection from "./GeneralSection";
import AboutLuc from "./AboutLuc";
import AboutSylvie from "./AboutSylvie";

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
        <div style={{ marginTop: '10rem' }}>
          <GeneralSection />
          <AboutLuc />
          <AboutSylvie />
        </div>
      </Layout>
    );
  }
}

export default index;
