import React from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col } from 'reactstrap';

const InformationText = () => {
  return (
    <section>
      <Container>
        <Row className="homeNfo text-center">
          <Col>
            <Fade bottom cascade>
              <div>
                <p>
                  Passionnés par l'Être Humain et ses Ressources infinies (mais si
                  étouffées dés notre enfance…), nous serons heureux de vous accueillir
                  dans un espace rassurant et détendu.
                </p>
                <p>
                  Vous écouter, entendre votre demande, mettre en lumière vos peurs
                  intérieures mais aussi votre immense potentiel en respectant votre
                  rythme, constitue l’Essentiel de notre mission auprès de vous.
                </p>
                <p>
                  Envisager sereinement des pistes de retrouvailles avec Vous-même afin de
                  transformer vos souffrances, vos fragilités, vos limitations en grandes
                  forces intérieures et enfin, dans une Profonde Conscience, (re)trouver
                  du Sens à votre Vie.
                </p>
                <p>
                  Lorsque vous vous sentez perdu(e) ou non aligné(e), il est tellement
                  Précieux de rencontrer une personne emplie de bienveillance, hors de
                  tout jugement et dans un lieu plein de tendresse.
                </p>
                <p>
                  Votre Premier pas vers cette démarche est le plus difficile car il est
                  suspendu à la Valeur que vous vous donnez. Êtes-vous prêt (e) à déployer
                  vos ailes et partir à l‘Aventure de la Découverte de Vous-M ‘aime?
                </p>
                <p>
                  Offrir votre « Bonheur de Vivre » aux personnes chères tout au long de
                  votre vie et, en toute conscience, Honorer votre Vivant !
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InformationText;
