import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Table } from 'reactstrap';


import Layout from '../layout';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
      <div style={{ marginTop: '20rem' }}>

      <Container className="text-center">
      <p>
          <h2><strong>Payment success</strong></h2>
      </p>

      </Container>

      </div>
      </Layout>
    );
  }
}

export default index;
