import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import SylvieImg from '../../../resources/images/about/sylvie.jpg';
import SylvieBook1 from '../../../resources/images/about/sylviebook1.png';
import SylvieBook2 from '../../../resources/images/about/sylviebook2.png';

class AboutSylvie extends Component {
  state = { modal: false };

  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal
    }));
    return false;
  };

  render() {
    return (
      <section>
        <Container>
          <Row className="text-justify">
            <Col xs="12" md="6">
              <Fade bottom cascade>
                <div>
                  <p>
                    <a
                      href="https://www.youtube.com/channel/UC81W-mFxB6qw-ofwnPbn5wQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Sylvie de Barnier{' '}
                    </a>
                    is a Psychologist and Psychotherapist, practicing for the last 35
                    years – first in France and now, since 2002, in Dubai.
                  </p>
                  <p>
                    In France she started as Psychologist at Alpes-Maritimes General
                    County Council for the Adoption, Gerontology and Educational
                    departments. She created her Psychotherapy Clinic in 1993. Her passion
                    lead her to become, since 2003, the Psychologist of the French High
                    School
                    <a href="javascript:void(0)" onClick={this.toggle}>
                      {' '}
                      Lycée Français International Georges Pompidou, Dubai
                    </a>
                    .
                  </p>
                  <p>
                    She is the author of two Life Note Carnets prefaced by
                    <a
                      href="http://www.j-salome.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      Jacques SALOMÉ
                    </a>
                    : ‘To You, My Mother’ and ‘To You, My Child’ – by Pygmalion Collection / Flammarion.
                  </p>
                </div>
              </Fade>
              <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-lg">
                <ModalHeader toggle={this.toggle}>
                  Lycée Français International Georges Pompidou, Dubai
                </ModalHeader>
                <ModalBody>
                  <p>
                    In 2001, upon our arrival in UAE, I enrolled my son at Lycée Georges
                    Pompidou, Sharjah. Few hundreds of kids were welcome there from
                    kindergarten to 12th grade, in an extremely family atmosphere.
                  </p>
                  <p>
                    Working in partnership with Professor Marcel RUFO of Marseille, I got
                    the mission from the Alpes-Maritimes County Council to organise ‘Open
                    Speaking Spaces’ for the teenagers, during school off periods, which
                    gave me a deep experience at the crucial, yet fragile duration of a
                    teenager’s time. I got the chance to meet some special needs
                    educators, art-therapists, sophrologists (very forward for that
                    time!), addiction-specialists, food-disorders specialists – all
                    sharing this wish to understand and support these young patients about
                    their Self-Confidence, as well as their Self-Love Constructions.
                  </p>
                  <p>
                    In this high school, far from France, I got lucky to experience
                    passionate, friendly attachment with Nicole Farouk – in charge of the
                    medical department. Nicole was deeply concerned about some ‘lost’
                    teenagers, far from their family, very often moving from one country
                    to another (parents transferred every 2 or 3 years to new countries),
                    facing friends’ attachments and separations, fragile emotional
                    securities etc.
                  </p>
                  <p>
                    We shared the same approach, based on a profound listening presence
                    and we created the ‘Listening Cell Centre’ at both, secondary and high
                    schools, which is still active today inside this (extremely nice)
                    Lycée Georges Pompidou, which is welcoming today more than 2,000
                    students and has shifted to Dubai in 2006.
                  </p>
                  <p>
                    Each year, I enjoy thoroughly working as a team with Head Masters,
                    Teachers, Educational staff – all very high qualified and deeply aware
                    of the impact of our words, behaviours and advices towards these
                    teenagers… the symbol of our future.
                  </p>
                  <p>
                    <a href="http://lfigp.org/" target="_blank" rel="noopener noreferrer">
                      Click for more info
                    </a>
                  </p>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
              <Container>
                <Row>
                  <Col xs="12" md="6">
                    <Fade up>
                      <img src={SylvieBook1} alt="SylvieBook" className="w-100 rounded" />
                    </Fade>
                  </Col>
                  <Col xs="12" md="6">
                    <Fade up delay={500}>
                      <img src={SylvieBook2} alt="SylvieBook" className="w-100 rounded" />
                    </Fade>
                  </Col>
                  <br/>
                </Row>
              </Container>
            </Col>
            <Col xs="12" md="6" className="text-center">
              <Fade right>
                <img src={SylvieImg} alt="Barnier" className="w-100 rounded" />
              </Fade>
              <br />
              <br />
              <p>
                <Fade down delay={500}>
                  <i>“If you don’t like what you harvest, then change what you sow!”</i>
                </Fade>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default AboutSylvie;
