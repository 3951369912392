import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Table } from 'reactstrap';

import ValuesHeaderImage from '../../../resources/images/values/values-header.jpeg';

import Layout from '../layout';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: '60vh',
            background: `url(${ValuesHeaderImage}) no-repeat`,
            backgroundSize: 'cover'
          }}
        >
          <h1
            className="text-uppercase  mt-5 header-text"
            style={{ fontSize: '3rem', fontWeight: 'bold' }}
          >
            Our Values
          </h1>
        </div>
        <Container className="mt-5">
          <Row>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <h2>
                  <strong>Vision</strong>
                </h2>
                <p style={{ fontSize: '1.3rem' }} className="mt-3">
                  Clarifying and understanding the meaning of our unconscious mechanisms,
                  unlocking the human potential, modifying our patterns and expanding our
                  limitations, using the keys of Neuro-Programming.
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom delay={500}>
                <h2>
                  <strong>Mission</strong>
                </h2>
                <p style={{ fontSize: '1.2rem' }} className="mt-3">
                  Welcoming individuals, couples and families in a warm and caring
                  environment, to provide qualitative and professional psychotherapy, as
                  well as coaching, by listening, guiding and counseling, without
                  judgment, but based on total empathy.
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom delay={1000}>
                <h2>
                  <strong>Values</strong>
                </h2>
                <br />
                <Table
                  bordered
                  className="text-black"
                  style={{
                    // fontSize: '1.2rem',
                    width: '60%',
                    border: '2px solid',
                    margin: 'auto'
                  }}
                >
                  <tbody>
                    <tr>
                      <td>Respect</td>
                      <td>Confidentiality</td>
                    </tr>
                    <tr>
                      <td colSpan="2">Ethical Code</td>
                    </tr>
                    <tr>
                      <td>Responsibility</td>
                      <td>Trust</td>
                    </tr>
                  </tbody>
                </Table>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }} className="mt-4">
                  “One shouldn’t be scared of anything in life, but strive to comprehend.”
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom delay={500}>
                <p style={{ fontSize: '1.3rem' }} className="mt-4">
                  “With empathy, we don’t direct, we follow. Don’t just do something, be
                  there.”
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom delay={500}>
                <p style={{ fontSize: '1.27rem' }} className="mt-4">
                  “Your beliefs become your thoughts, Your thoughts become your words,
                  Your words become your actions, Your actions become your habits, Your
                  habits become your values, Your values become your destiny.”
                </p>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }}>
                  <strong>
                    <i>- Marie Curie</i>
                  </strong>
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }}>
                  <strong>
                    <i>- Marshall Rosenberg</i>
                  </strong>
                </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="4"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.3rem' }}>
                  <strong>
                    <i>- Gandhi</i>
                  </strong>
                </p>
              </Fade>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default index;
