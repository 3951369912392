import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';
import LightSpeed from 'react-reveal/LightSpeed';

import { Container, Row, Col } from 'reactstrap';

import Layout from '../layout';

import SpecialitiesHeaderImage from '../../../resources/images/specialities/specialities-header.jpg';
import image1 from '../../../resources/images/specialities/img1.jpeg';
import image2 from '../../../resources/images/specialities/img2.jpg';
import image3 from '../../../resources/images/specialities/img3.jpg';
import image4 from '../../../resources/images/specialities/img4.jpg';
import image5 from '../../../resources/images/specialities/img5.jpg';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            height: '60vh',
            background: `url(${SpecialitiesHeaderImage}) no-repeat`,
            backgroundSize: 'cover'
          }}
        >
          <h1
            className="text-uppercase  mt-5 header-text"
            style={{ fontSize: '3rem', fontWeight: 'bold' }}
          >
            Our Specialities
          </h1>
        </div>
        <section className="mt-5">
          <Container>
            <Row>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img
                    src={image1}
                    alt="Children, Teenages, Families"
                    style={{ width: '90%' }}
                  />
                </Zoom>
              </Col>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed right>
                  <h3>Psychotherapy for Children, Teenagers, Families</h3>
                  <ul className="mt-3">
                    <li>Anxiety</li>
                    <li>Phobia</li>
                    <li>Depression</li>
                    <li>Food Disorders</li>
                    <li>Bullying</li>
                  </ul>
                </LightSpeed>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed left>
                  <h3>Psychotherapy for Adults</h3>
                  <ul className="ml-3">
                    <li>One to one</li>
                    <li>Couple</li>
                    <li>Group</li>
                  </ul>
                </LightSpeed>
              </Col>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img src={image2} alt="Psychotherapy" style={{ width: '90%' }} />
                </Zoom>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img
                    src={image3}
                    alt="Post Traumatic Syndrome"
                    style={{ width: '90%' }}
                  />
                </Zoom>
              </Col>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed right>
                  <h3>Post Traumatic Syndrome</h3>
                  <p className="mt-3">
                    Helping towards recovering over a trauma via specific methods
                  </p>
                  <p>(Hypnosis, NLP, etc.)</p>
                </LightSpeed>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed left>
                  <h3>Psychological Evaluations</h3>

                  <p className="mt-3">Cognitive and Emotional Evaluations (IQ etc.)</p>
                </LightSpeed>
              </Col>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img
                    src={image4}
                    alt="Psychological Evaluations"
                    style={{ width: '90%' }}
                  />
                </Zoom>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs="12" md="6" className="text-center">
                <Zoom>
                  <img
                    src={image5}
                    alt="Life Coaching & Corporate Coaching"
                    style={{ width: '90%' }}
                  />
                </Zoom>
              </Col>
              <Col
                xs="12"
                md="6"
                className="d-flex flex-column justify-content-center align-items-center text-center"
              >
                <LightSpeed right>
                  <h3>Life Coaching & Corporate Coaching</h3>
                  <p className="mt-3">
                    Tailor-made coaching, adjusted to each person and their issue, towards
                    solving corporate coaching inside the company, for a group or for a
                    team
                  </p>
                </LightSpeed>
              </Col>
            </Row>
          </Container>
        </section>
      </Layout>
    );
  }
}

export default index;
