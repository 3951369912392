import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Routes
import ChangePath from "./components/ChangePath";
import enHome from "./components/en/home";
import enAbout from "./components/en/about";
import enValues from "./components/en/values";
import enSpecialities from "./components/en/specialities";
import enPricing from "./components/en/pricing";
import enContact from "./components/en/contact";
import enTestimonies from "./components/en/testimonies";

import frHome from "./components/fr/home";
import frAbout from "./components/fr/about";
import frValues from "./components/fr/values";
import frSpecialities from "./components/fr/specialities";
import frPricing from "./components/fr/pricing";
import frContact from "./components/fr/contact";
import frTestimonies from "./components/fr/testimonies";


import enAcceptableUsePolicy from "./components/en/footer/acceptable-use-policy";
import enCookiePolicy from "./components/en/footer/cookie-policy";
import enDisclaimer from "./components/en/footer/disclaimer";
import enPrivacyPolicy from "./components/en/footer/privacy-policy";
import enTermsAndConditions from "./components/en/footer/terms-and-conditions";

import frAcceptableUsePolicy from "./components/fr/footer/acceptable-use-policy";
import frCookiePolicy from "./components/fr/footer/cookie-policy";
import frDisclaimer from "./components/fr/footer/disclaimer";
import frPrivacyPolicy from "./components/fr/footer/privacy-policy";
import frTermsAndConditions from "./components/fr/footer/terms-and-conditions";

import enPayment from "./components/en/payment";
import frPayment from "./components/fr/payment";


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" name="Move to Home" component={ChangePath} />
          <Route exact path="/en" name="Home Page" component={enHome} />
          <Route exact path="/en/about" name="About Page" component={enAbout} />
          <Route
            exact
            path="/en/values"
            name="Values Page"
            component={enValues}
          />
          <Route
            exact
            path="/en/specialities"
            name="Specialities Page"
            component={enSpecialities}
          />
          <Route
            exact
            path="/en/pricing"
            name="Pricing Page"
            component={enPricing}
          />
          <Route
            exact
            path="/en/contact"
            name="Contact Page"
            component={enContact}
          />
          <Route
            exact
            path="/en/testimonies"
            name="Testimonies Page"
            component={enTestimonies}
          />

          <Route exact path="/fr" name="Home Page" component={frHome} />
          <Route exact path="/fr/about" name="About Page" component={frAbout} />
          <Route
            exact
            path="/fr/values"
            name="Values Page"
            component={frValues}
          />
          <Route
            exact
            path="/fr/specialities"
            name="Specialities Page"
            component={frSpecialities}
          />
          <Route
            exact
            path="/fr/pricing"
            name="Pricing Page"
            component={frPricing}
          />
          <Route
            exact
            path="/fr/contact"
            name="Contact Page"
            component={frContact}
          />
          <Route
            exact
            path="/fr/testimonies"
            name="Testimonies Page"
            component={frTestimonies}
          />

          <Route
            exact
            path="/fr/acceptable-use-policy"
            name="Acceptable Use Policy"
            component={frAcceptableUsePolicy}
          />
          <Route
            exact
            path="/fr/cookie-policy"
            name="Cookie Policy"
            component={frCookiePolicy}
          />
          <Route
            exact
            path="/fr/disclaimer"
            name="Disclaimer"
            component={frDisclaimer}
          />
          <Route
            exact
            path="/fr/privacy-policy"
            name="Privacy Policy"
            component={frPrivacyPolicy}
          />
          <Route
            exact
            path="/fr/terms-and-condition"
            name="Terms and Conditions"
            component={frTermsAndConditions}
          />

          <Route
            exact
            path="/en/acceptable-use-policy"
            name="Acceptable Use Policy"
            component={frAcceptableUsePolicy}
            />
          <Route
            exact
            path="/en/cookie-policy"
            name="Cookie Policy"
            component={frCookiePolicy}
          />
          <Route
            exact
            path="/en/disclaimer"
            name="Disclaimer"
            component={frDisclaimer}
          />
          <Route
            exact
            path="/en/privacy-policy"
            name="Privacy Policy"
            component={frPrivacyPolicy}
          />
          <Route
            exact
            path="/en/terms-and-condition"
            name="Terms and Conditions"
            component={frTermsAndConditions}
          />


          <Route
            exact
            path="/en/payment"
            name="Payment"
            component={enPayment}
          />
          <Route
            exact
            path="/fr/payment"
            name="Payment"
            component={frPayment}
          />


        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
