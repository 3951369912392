import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Table } from 'reactstrap';


import Layout from '../../layout';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
      <div style={{ marginTop: '20rem' }}>

      <Container className="text-left">
      <p>
    <h2><strong>Cookie Policy</strong></h2>
</p>
<p>
    <strong></strong>
    <br/>
</p>
<p>
    This cookie policy ("Policy") describes what cookies are and how JOY &amp;
    GOLDEN LINK FZ-LLC ("JOY &amp; GOLDEN LINK FZ-LLC", "we", "us" or "our")
    uses them on the www.yeahpsychologydubai.life website and any of its
    products or services (collectively, "Website" or "Services").
</p>
<p>
    You should read this Policy so you can understand what type of cookies we
    use, the information we collect using cookies and how that information is
    used. It also describes the choices available to you regarding accepting or
    declining the use of cookies. For further information on how we use, store
    and keep your personal data secure, see our Privacy Policy.
</p>
<p>
    <strong>What are cookies?</strong>
</p>
<p>
    Cookies are small pieces of data stored in text Qles that are saved on your
    computer or other devices when websites are loaded in a browser. They are
    widely used to remember you and your preferences, either for a single visit
    (through a "session cookie") or for multiple repeat visits (using a
    "persistent cookie").
</p>
<p>
    Session cookies are temporary cookies that are used during the course of
    your visit to the Website, and they expire when you close the web browser.
</p>
<p>
    Persistent cookies are used to remember your preferences within our Website
    and remain on your desktop or mobile device even after you close your
    browser or restart your computer. They ensure a consistent and efficient
    experience for you while visiting our Website or using our Services.
</p>
<p>
    Cookies may be set by the Website ("first-party cookies"), or by third
    parties, such as those who serve content or provide advertising or
    analytics services on the website ("third party cookies"). These third
    parties can recognize you when you visit our website and also when you
    visit certain other websites.
</p>
<p>
    <strong>What type of cookies do we use?</strong>
</p>
<p>
    <strong>Necessary cookies</strong>
</p>
<p>
    Necessary cookies allow us to offer you the best possible experience when
    accessing and navigating through our Website and using its features. For
    example, these cookies let us recognize that you have created an account
    and have logged into that account to access the content.
</p>
<p>
    <strong>Functionality cookies</strong>
</p>
<p>
    Functionality cookies let us operate the Website and our Services in
    accordance with the choices you make. For example, we will recognize your
    username and remember how you customized the Website and Services during
    future visits.
</p>
<p>
    <strong>Analytical cookies</strong>
</p>
<p>
    These cookies enable us and third party services to collect aggregated data
    for statistical purposes on how our visitors use the Website. These cookies
    do not contain personal information such as names and email addresses and
    are used to help us improve your user experience of the Website.
</p>
<p>
    <strong>Do we use web beacons or tracking pixels?</strong>
</p>
<p>
    Our emails may contain a "web beacon" (or "tracking pixel") to tell us
    whether our emails are opened and verify any clicks through to links or
    advertisements within the email.
</p>
<p>
    We may use this information for purposes including determining which of our
    emails are more interesting to users and to query whether users who do not
    open our emails wish to continue receiving them.
</p>
<p>
    The pixel will be deleted when you delete the email. If you do not wish the
    pixel to be downloaded to your device, you should read the email in plain
    text view or with images disabled.
</p>
<p>
    <strong>What are your cookie options?</strong>
</p>
<p>
    If you don't like the idea of cookies or certain types of cookies, you can
    change your browser's settings to delete cookies that have already been set
    and to not accept new cookies. To learn more about how to do this or to
    learn more about cookies, visit internetcookies.org
</p>
<p>
    Please note, however, that if you delete cookies or do not accept them, you
    might not be able to use all of the features our Website and Services
    offer.
</p>
<p>
    <strong>Changes and amendments</strong>
</p>
<p>
    We reserve the right to modify this Policy relating to the Website or
    Services at any time, effective upon posting of an updated version of this
    Policy on the Website. When we do we will revise the updated date at the
    bottom of this page. Continued use of the Website after any such changes
    shall constitute your consent to such changes.
</p>
<p>
    <strong>Acceptance of this policy</strong>
</p>
<p>
    You acknowledge that you have read this Policy and agree to all its terms
    and conditions. By using the Website or its Services you agree to be bound
    by this Policy. If you do not agree to abide by the terms of this Policy,
    you are not authorized to use or access the Website and its Services.
</p>
<p>
    <strong>Contacting us</strong>
</p>
<p>
    If you would like to contact us to understand more about this Agreement or
    wish to contact us concerning any matter relating to it, you may write a
    letter to JOY &amp; GOLDEN LINK FZ-LLC, PO Box 476306, Dubai, United Arab
    Emirates.
</p>

      </Container>

      </div>
      </Layout>
    );
  }
}

export default index;
