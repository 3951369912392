import React, { Component, Fragment } from "react";

import Layout from "../layout";
import Slider from "./Slider";
import InformationText from "./InformationText";

import Logo from "../../../static/compressed.gif";

class index extends Component {
  state = {
    logoAnimation: "",
    zIndex: ""
  };
  componentDidMount() {
    window.scroll(0, 0);
    if (!sessionStorage.animation) {
      sessionStorage.setItem("animation", true);
      this.setState({ logoAnimation: "logo-animation-add" });
      setTimeout(() => {
        this.setState({ logoAnimation: "logo-animation-remove" });
        setTimeout(() => {
          this.setState({ zIndex: "-1" });
        }, 2000);
      }, 9500);
    } 
    else {
      this.setState({ logoAnimation: "remove-div" });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <Fragment>
        <div
          className={this.state.logoAnimation}
          style={{ zIndex: `${this.state.zIndex}` }}
        >
          <img src={Logo} alt="Site Logo" style={{width:"60%"}} />
        </div>
        <div className={this.state.mainScreen}>
          <Layout>
            <Slider />
            <InformationText />
          </Layout>
        </div>
      </Fragment>
    );
  }
}

export default index;
