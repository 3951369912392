import React from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";

const MobileMenu = props => {
  let mobileMenuClass = "mobile-menu pt-3";
  if (props.show) {
    mobileMenuClass = "mobile-menu pt-3 open";
  }
  return (
    <div>
      {props.show && (
        <div
          className="layer"
          style={{ position: "fixed", zIndex: "1" }}
          onClick={props.closeMobileMenu}
        />
      )}
      <Nav className={mobileMenuClass} navbar>
        <NavItem onClick={props.closeMobileMenu}>
          <NavLink
            className="navbar-link"
            activeClassName="active_item"
            exact
            to="/en"
          >
            Home
          </NavLink>
        </NavItem>
        <NavItem onClick={props.closeMobileMenu}>
          <NavLink
            className="navbar-link"
            activeClassName="active_item"
            to="/en/about"
          >
            About Us
          </NavLink>
        </NavItem>
        <NavItem onClick={props.closeMobileMenu}>
          <NavLink
            className="navbar-link"
            activeClassName="active_item"
            to="/en/values"
          >
            Our Values
          </NavLink>
        </NavItem>
        <NavItem onClick={props.closeMobileMenu}>
          <NavLink
            className="navbar-link"
            activeClassName="active_item"
            to="/en/specialities"
          >
            Our Specialities
          </NavLink>
        </NavItem>
        <NavItem onClick={props.closeMobileMenu}>
          <NavLink
            className="navbar-link"
            activeClassName="active_item"
            to="/en/pricing"
          >
            Our Fees
          </NavLink>
        </NavItem>
        <NavItem onClick={props.closeMobileMenu}>
          <NavLink
            className="navbar-link"
            activeClassName="active_item"
            to="/en/contact"
          >
            Contact Us
          </NavLink>
        </NavItem>

        <NavItem onClick={props.closeMobileMenu}>
          <NavLink
            className="navbar-link"
            activeClassName="active_item"
            to="/en/testimonies"
          >
            Testimonies
          </NavLink>
        </NavItem>
        <NavItem onClick={props.closeMobileMenu}>
          <NavLink
            className="navbar-link mr-1"
            activeClassName="active_item"
            to="/en"
          >
            EN
          </NavLink>
          |
          <NavLink
            className="navbar-link ml-1"
            activeClassName="active_item"
            to="/fr"
          >
            FR
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default MobileMenu;
