import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import { Container, Row, Col, Table } from 'reactstrap';

import ValuesHeaderImage from '../../../resources/images/testimonies/testimonies-header.jpg';

import Layout from '../layout';

class index extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Layout>
        <div
          className="d-flex align-items-end justify-content-end"
          style={{
            height: '60vh',
            background: `url(${ValuesHeaderImage}) no-repeat`,
            backgroundSize: 'cover',
            padding: '40px'
          }}
        >
          <h1
            className="text-right  mt-5 header-text italic-quote"
          >
          "Vos témoignages, vos paroles auront le don de mettre de la lumière sur notre site comme le soleil du matin sur le sable d'une plage...
          <br/>
          Par avance, Merci beaucoup !"
          </h1>
        </div>
        <Container className="mt-5">
          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
                <p style={{ fontSize: '1.1rem' }} className="mt-3">
                Réconfort-confort- - - <br/>
                Vers un discernement des rayons de lumière- - -
                </p>
                <p style={{ fontSize: '1.1rem' }}>
                  <strong>
                    <i>- BS</i>
                  </strong>
                </p>

              </Fade>
            </Col>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom delay={500}>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
De l’apaisement et du bien être au milieu de ce défoulement d’énergie négative sur tous les médias et réseaux sociaux. Des messages qui nous guident et qui raisonnent en nous. Merci              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- MW</i>
                </strong>
              </p>
              </Fade>
            </Col>
          </Row>
          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
Il y a des rencontres dans le parcours d’une vie qui ne vous laissent pas indifférent! Luc et Sylvie en font désormais partie. Ils ont été complémentaires et bienveillants dans ce travail sur moi même, ils m’ont accompagné et guidé quand j’en avais le plus besoin. Maintenant je peux avancer et guérir...merci.
              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- Laurence</i>
                </strong>
              </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              Merci à Sylvie et Luc pour leur gentillesse, leur plénitude et leur présence qui m'ont permis d'ouvrir une partie de moi-même à laquelle je n'avais pas accès.

              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- YB</i>
                </strong>
              </p>
              </Fade>
            </Col>
          </Row>

          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
Avec un résultat intense et exceptionnel, Sylvie et Luc m'ont fourni tout ce qu'il fallait pour prospérer et surmonter tous les défis personnels et professionnels. Merci beaucoup!
              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- GGK</i>
                </strong>
              </p>
              </Fade>
            </Col>

            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              Merci Luc et Sylvie pour votre présence et votre soutien dans cette période cahotique. Vous m’avez ouvert les yeux et surtout donné le courage d’affronter certains de mes démons avec la certitude que je ne risquais rien et cela ne pouvait déboucher que sur du meilleur et c’est le cas. Merci à vous deux, vraiment.
              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                <i>- A-V</i>
                </strong>
              </p>
              </Fade>
            </Col>



          </Row>

          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              Après une vingtaine d’année de vie ensemble, notre couple avait besoin d’un nouveau départ.
              Nous avons commencé notre thérapie de couple avec Sylvie et Luc.
              Suite à la première séance, nous avons chacun poursuivi une thérapie individuelle. Nous avons tous les 2 non seulement sauvé notre couple mais nous avons trouvé les clefs pour un épanouissement personnel.
              L’étape suivante a été d’inclure nos enfants dans ce schéma thérapeutique.
              Bref, je ne peux que vous conseiller Sylvie et Luc.
              L’un comme l’autre sont remplis d’amour à partager, le tout dans une approche professionnelle.              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- BD</i>
                </strong>
              </p>
              </Fade>
            </Col>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
Il y a des explications concernant la façon dont nous nous sommes construits - qui nous apparaissent plus ou moins de manière limpide. Luc vous apporte la lumière nécessaire sur certains épisodes sombres de notre passé, mais pas que ! Il vous apporte une logique, qui aide à mettre certains points sur les "i". Je ne me sentais pas fragile avant les séances et n'éprouvais pas de besoin particulier - mais son aide m'a rendu plus serein fasse à la question existentielle de qui l'on est vraiment. Le temps file en sa compagnie...
              </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- TG</i>
                </strong>
              </p>
              </Fade>
            </Col>
          </Row>

          <Row>
            <Col
              xs="12"
              md="6"
              className="text-center"
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
            >
              <Fade bottom>
              <p style={{ fontSize: '1.1rem' }} className="mt-3">
              Un grand merci à Sylvie et à Luc pour leurs précieux conseils et explications  apportés dans chacune des vidéos proposées.
        </p>
              <p style={{ fontSize: '1.1rem' }}>
                <strong>
                  <i>- RM</i>
                </strong>
              </p>
              </Fade>
            </Col>

            </Row>



        </Container>
      </Layout>
    );
  }
}

export default index;
