import React, { Component } from 'react';
import { Carousel, CarouselItem } from 'reactstrap';

const items = [
  {
    text: 'Psychothérapie'
  },
  {
    text: 'Développement Personnel'
  },
  {
    text: 'Bien Être'
  }
];

class Slider extends Component {
  state = { activeIndex: 0 };

  next = () => {
    const nextIndex =
      this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    const nextIndex =
      this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem key={item.text}>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: '100vh' }}
          >
            <h1
              className="text-uppercase text-white text-center slider-text"
              style={{ fontWeight: 'bold' }}
            >
              {item.text}
            </h1>
          </div>
        </CarouselItem>
      );
    });
    return (
      <div className="slider">
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          ride="carousel"
          pause={false}
        >
          {slides}
        </Carousel>
      </div>
    );
  }
}

export default Slider;
