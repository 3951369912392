  import React from 'react';
  import Fade from 'react-reveal/Fade';

  import { Container, Row, Col } from 'reactstrap';

  const ReachUs = () => {
    return (
      <Fade bottom>
        <Container className="contact">
          <Row>

          <Col xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
            <div
              style={{
                fontSize: '1.2rem',
                width: '100%',
                border: '2px solid'
              }}
            >
              <p>Sylvie de Barnier</p>
              <p>
                <i className="fas fa-phone alt fa-1x" />
                (+971) 50 78 510 79
              </p>
              <i className="fas fa-envelope fa-1x" />
              <a href="mailto:debarniersylvie@yahoo.fr" target="_top">
                E-mail : debarniersylvie@yahoo.fr
              </a>
              <br />
              <a
                href="https://www.youtube.com/channel/UC81W-mFxB6qw-ofwnPbn5wQ"
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="fab fa-youtube fa-2x mt-3" />
              </a>
            </div>
          </Col>

          <Col xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
              <div
                style={{
                  fontSize: '1.2rem',
                  width: '100%',
                  border: '2px solid'
                }}
              >
                <i className="fas fa-map-marker-alt fa-2x mt-3" />
                <br />
                <a href="https://goo.gl/maps/ztmuBeKvHJF86Er66"
                target="_blank"
                rel="noopener noreferrer" >
                 Office 4-007, Ground Floor,
                <br /> Building 4, Studio City, Dubai, UAE
                </a>
                <br /><br />
                <i className="fas fa-envelope fa-1x" />
                <a href="mailto:SylvieandLuc@yeahpsychologydubai.life" target="_top">
                E-mail :
                </a> <br />
                <a href="mailto:SylvieandLuc@yeahpsychologydubai.life" target="_top" style={{
                  fontSize: '1.0rem', wordBreak: 'break-all'
                }}>
                SylvieandLuc@yeahpsychologydubai.life
                </a>

                </div>
                </Col>

                <Col xs={12} sm={6} lg={4} style={{ display: 'flex' }}>
                <div
                style={{
                  fontSize: '1.2rem',
                  width: '100%',
                  border: '2px solid'
                }}
              >
                <p>Luc M. de Barnier</p>
                <p>
                  <i className="fas fa-phone alt fa-1x" />
                  (+971) 52 848 3333
                </p>
                <i className="fas fa-envelope fa-1x" />
                <a href="mailto:lucdebarnier@gmail.com" target="_top">
                  E-mail : lucdebarnier@gmail.com
                </a>
                <br />
                <a
                  href="https://www.facebook.com/yeahdubai/?modal=admin_todo_tour"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square fa-2x mt-3" />
                </a>
              </div>
            </Col>

          </Row>
        </Container>
      </Fade>
    );
  };

  export default ReachUs;
